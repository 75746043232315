<template>
  <div>
    <!--    <NuxtRouteAnnouncer />-->
    <!--    <NuxtWelcome />-->

    <NuxtPage/>
    <el-dialog
        v-model="loginDialogVisible"
        width="355px"
        :show-close="false"
        align-center
        style="border-radius: 1.5rem;"
        :before-close="handelLoginDone">
      <LoginLayout @onCloseLoginDialog="handelLoginDone"></LoginLayout>
    </el-dialog>

  </div>
</template>
<script setup>
import {getSpaceTypeList} from "@/api/space";
import {citySlug} from "@/config/common.js";
const usageCategoryId = import.meta.env.VITE_APP_BASE_USAGE_CATEGORY_ID.toString()

const settingStore = useSettingStore()
const userStore = useUserStore()
const filterStore = useFilterStore();
const categoryStore = useCategoryStore()
const cityStore = useCityStore();

const loginDialogVisible = computed(() => settingStore.isLoginDialogVisible);


handleSpaceTypeList()


function handelLoginDone() {
  settingStore.updateLoginDialogVisible(false)
}

async function handleSpaceTypeList() {
  try {

    const {data: spaceTypeListData} = await useAsyncData(
        'spaceTypeListData',
        () => {
          return getSpaceTypeList()
        });

    if (spaceTypeListData.value.equipments) {
      filterStore.setEquipmentList(spaceTypeListData.value.equipments)
    }
    if (spaceTypeListData.value.services) {
      filterStore.setServiceList(spaceTypeListData.value.services)
    }

    if (spaceTypeListData.value.categories) {
      spaceTypeListData.value.categories.subs.forEach(topCategories => {
        topCategories.subs = topCategories.subs.filter(item => spaceTypeListData.value.amounts.categories[item.id] > 0);
      });


    /*  // 用途增加 slug
      console.log('spaceTypeListData.value.categories.subs[usageCategoryId] = ', spaceTypeListData.value.categories.subs);
      spaceTypeListData.value.categories.subs.forEach(item => {
        if (item.id === parseInt(usageCategoryId)) {
          console.log('usageCategoryId item.subs = ',item.subs);
        }
      })*/


      categoryStore.setCategories(spaceTypeListData.value.categories.subs)
      categoryStore.setSpaceTypeList(categoryStore.getSpaceTypeCategories)

      // todo 檢查還有沒有要用，可以拿掉
      // 塞沒有相依的風格
      filterStore.setCategoryTagList(categoryStore.getTagCategories)
      filterStore.setCategoryTypeList(categoryStore.getSpaceTypeCategories)
    }

    // 所有的空間數量
    if (spaceTypeListData.value.amounts) {
      categoryStore.setAmounts(spaceTypeListData.value.amounts)
    }

    if (spaceTypeListData.value.cities) {

      // 只有更新amount
      /*spaceTypeListData.value.cities.forEach(item => {
        const cityId = item.city_id.toString();
        if (spaceTypeListData.value.amounts.cities.hasOwnProperty(cityId)) {
          item.amount = spaceTypeListData.value.amounts.cities[cityId]; // 更新amount值
        }
      });*/


      //  這邊要改成只有amounts>0的城市才存store
      const cityList = spaceTypeListData.value.cities.filter(item => {
        const cityId = item.city_id.toString();
        if (spaceTypeListData.value.amounts.cities.hasOwnProperty(cityId) && spaceTypeListData.value.amounts.cities[cityId] > 0) {
          item.amount = spaceTypeListData.value.amounts.cities[cityId]; // 更新amount值
          item.slug = citySlug[cityId] || ''; // 補上 slug，若無對應則給空字串
          return item
        }

      });
      cityStore.setCityList(cityList)
    }

  } catch (error) {
    console.log(error)
  }
}


function getLoginInfoFromUrl() {
  const agentDomainURL = new URL(window.location.toString())
  const params = agentDomainURL.searchParams
  if (params.has('token') && params.get('token')) {
    userStore.updateToken(params.get('token'))
    userStore.updateUserInfo()
  }
}

function detectDeviceType() {

  const viewportWidth = window.innerWidth;
  if (viewportWidth <= 768) {
    settingStore.updateIsMobile(true);
  } else {
    settingStore.updateIsMobile(false);
  }

}

onMounted(() => {
  console.log(import.meta.env.VITE_BUILD_DATE);
  console.log(import.meta.env.VITE_ENV);
  settingStore.updateIsShowLog(import.meta.env.VITE_ENV === 'dev')
  detectDeviceType();
  getLoginInfoFromUrl()
  window.addEventListener('resize', detectDeviceType);


  // 時間格式化
  Date.prototype.format = function (fmt) {
    const o = {
      'M+': this.getMonth() + 1, // 月份
      'd+': this.getDate(), // 日
      'h+': this.getHours(), // 小时
      'm+': this.getMinutes(), // 分
      's+': this.getSeconds(), // 秒
      'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
      S: this.getMilliseconds() // 毫秒
    }
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (const k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
      }
    }
    return fmt
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', detectDeviceType);
})
</script>
<style>

.messageClass {
  z-index: 3000 !important;
}
</style>
