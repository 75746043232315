import httpRequest from "~/composables/requset";
import type {ApiResponse, ResponseData} from '~/utils/types';
import {DateFormat} from "~/utils/dateFormat";


// 廠商地點列表
export function locationList(data): Promise<ResponseData> {
    return httpRequest(
        'POST',
        '/api/platform/space/location-list',
        data
    )
}

// 空間列表
export function getSpaceList(pagination, selectSpaceType: number, adminID: number, failFunc?: any): Promise<ResponseData> {

    const queryData = {
        pagination,
        type: selectSpaceType,
        admin_id: adminID
    }

    return httpRequest(
        'POST',
        '/api/platform/space/list',
        queryData,
        failFunc
    )
}

// 下拉空間列表 改拿 categories
export function getSpaceTypeList(): Promise<ResponseData> {
    return httpRequest(
        'POST',
        '/api/platform/space/type-list'
    );
}

// 空間詳情
export function getSpaceInfo(id: number, errHandle?: any): Promise<ResponseData> {

    const queryData = {space_id: id}

    return httpRequest(
        'POST',
        '/api/space/info',
        queryData,
        errHandle
    )
}

// 空間使用情形
export function getSpaceUsageStatus(spaceId: number, date: string): Promise<ResponseData> {

    const queryData = {
        space_id: spaceId,
        date: date
    }
    return httpRequest(
        'POST',
        '/api/space/usage-status',
        queryData
    )
}

// 模糊搜尋
export function getSpaceKeywordSearch(data): Promise<ResponseData> {
    return httpRequest(
        'POST',
        '/api/platform/space/location-list/keyword',
        data
    )
}

export function getSearchSpaceList(data): Promise<ResponseData> {
    return httpRequest(
        'POST',
        '/api/platform/spaces',
        data
    )
}

// admin id，找該空間列表
export function spaceListByAdmin(data): Promise<ResponseData> {
    return httpRequest(
        'POST',
        '/api/platform/spaces-by-admin',
        data
    )
}
