<template>
  <svg aria-hidden="true"
       class="svg-icon"
       :width="props.size"
       :height="props.size"
       :style="{ fill: props.color,width: `${props.size}px`, height: `${props.size}px` }">
    <use :href="symbolId"
         :fill="props.color"/>
  </svg>
</template>
<script setup>
import {computed} from 'vue'

const props = defineProps({
  prefix: {
    type: String,
    default: 'icon'
  },
  name: {
    type: String,
    required: true
  },
  color: {
    type: String,
    default: '#333'
  },
  size: {
    type: String,
    default: '1em'
  }
})

const symbolId = computed(() => `#${props.prefix}-${props.name}`)

</script>

<style scoped
       lang="scss">

</style>
